<template>
  <div class="cards-filters-tmk">


    <div class="cards-filters-tmk-side">
      <search-input
        v-if="isFilterVisible('search')"
        placeholder="Поиск по № карты, ФИО, Должности, Подразделению"
        style="width: 480px"
        v-model="filters.search"
        @change="filtersChanged"
      >
      </search-input>

      <cards-year-select
        v-if="isFilterVisible('years')"
        style="width:100px"
        v-model="filters.years"
        @change="filtersChanged"
      ></cards-year-select>

      <cards-period-select
        v-if="isFilterVisible('periods')"
        style="width:100px"
        v-model="filters.periods"
        @change="filtersChanged"
      ></cards-period-select>

      <cards-stage-select
        v-if="isFilterVisible('stages')"
        placeholder="Текущий этап"
        multiple
        v-model="filters.stages"
        @change="filtersChanged"
      ></cards-stage-select>




<!--      <cards-status-select-->
<!--        v-if="isFilterVisible('status')"-->
<!--        style="width: 200px"-->
<!--        v-model="filters.statuses"-->
<!--        @change="filtersChanged"-->
<!--      ></cards-status-select>-->

    </div>


    <div class="cards-filters-tmk-side">
<!--      <el-checkbox-->
<!--        v-if="isFilterVisible('my_turn')"-->
<!--        style="margin-right: 0"-->
<!--        v-model="filters.my_turn"-->
<!--        border-->
<!--        :true-label="1"-->
<!--        :false-label="null"-->
<!--        @change="filtersChanged"-->
<!--      >-->
<!--        Карты на моем шаге-->
<!--      </el-checkbox>-->
      <el-select
        style="width: 300px"
        v-model="availableCards"
        multiple
        placeholder="Доступные карты"
      >
        <el-option label="Мои карты" value="my"></el-option>
        <el-option label="На моем шаге" value="my_turn"></el-option>
        <el-option label="Карты непосредственных подчиненных" value="subordinates"></el-option>
        <el-option label="Карты всех подчиненных" value="allSubordinates"></el-option>
      </el-select>
<!--      <el-checkbox-->
<!--        v-if="isFilterVisible('my')"-->
<!--        style="margin-right: 0"-->
<!--        v-model="filters.my"-->
<!--        border-->
<!--        :true-label="1"-->
<!--        :false-label="null"-->
<!--        @change="filtersChanged"-->
<!--      >-->
<!--        Мои карты-->
<!--      </el-checkbox>-->

    </div>

  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";

export default {
  name: "cards-page-filters",
  components: {
    CardsStatusSelect,
    UserSelect,
    CardsPeriodSelect, CardsStageNegotiationSelect, CardsStageSelect, CardsYearSelect, SearchInput},

  props: {
    value: {},
    hidden: {type: Array},
  },

  computed: {},
  watch: {
    availableCards: function () {
      this.filters.my = this.availableCards.includes('my') ? 1 : null;
      this.filters.my_turn = this.availableCards.includes('my_turn') ? 1 : null;

      if( this.availableCards.includes('allSubordinates') ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_ALL;
      } else if( this.availableCards.includes('subordinates') ){
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_DIRECT;
      } else {
        this.filters.subordinates = this.$constants.user.subordinates.SUBORDINATES_NONE;
      }



      this.filtersChanged();
    },
  },
  data() {
    return {
      availableCards: [],
      filters: {
        search: null,
        negotiation_user_ids: null,
        statuses: null,
        my: null,
        my_turn: null,
        type: null,
        subordinates: null,
      },
    }
  },
  beforeMount() {
    this.filters.my = this.$route.query.my ? 1 : null;
    if( this.filters.my ){
      this.availableCards.push('my');
    }
    this.filters.my_turn = this.$route.query.my_turn ? 1 : null;
    if( this.filters.my_turn ){
      this.availableCards.push('my_turn');
    }
  },
  mounted() {
    this.filters = {...this.filters, ...this.value};

    this.filtersChanged()
  },
  methods: {
    filtersChanged() {
      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    },

    isFilterVisible(filterName){
      if( this.hidden && this.hidden.includes(filterName) ){
        return false;
      }
      return true;
    },
  }
}
</script>


<style lang="scss">

.cards-filters-tmk {
  display: flex;
  justify-content: space-between;

  &-side {
    align-items: self-start;
    display: flex;
    gap: 1.5rem;
  }
}
</style>